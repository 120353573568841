<template>
  <div>
    <PageHeader text="Страница создания профиля" />
    <ProfileForm />
  </div>
</template>

<script>
import ProfileForm from '@/components/forms/ProfileForm.vue'
import PageHeader from '@/components/common/PageHeader.vue'

export default {
  components: {
    ProfileForm,
    PageHeader,
  },
}
</script>
